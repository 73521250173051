/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'
import {
  Alert,
  Box,
  Card,
  CardContent,
  Grid2,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { MarkElement } from '@mui/x-charts/LineChart'
import Markdown from 'markdown-to-jsx'
import DataTableGrid from './components/DataTableGrid'
import { MarkElementProps } from '@mui/x-charts'
import { useState, useEffect } from 'react'
import LineChartGrid from './components/LineChartGrid'
import { DropDownProps, MainSeriesProps, ReportRenderProps } from '../../types'
import {
  compareArrays,
  getBarColors,
  specificColumnData,
  totalData,
} from '../../utils'
import { LINE_COLORS } from '../../../../utils/constants'

const ReportRenderer: React.FC<ReportRenderProps> = ({
  data,
  props,
  rows,
  columns,
  columnGroupingModel,
  rowGroupingModel,
  pinnedRows,
  setRowGroupingModel,
  pinnedColumnsData,
  chartData,
  offset_seconds,
  source,
}) => {
  const [chartWidth, setChartWidth] = useState<number | undefined>()
  const [currentColumns, setCurrentColumns] = useState<string>('Gender')
  const [dropDownData, setDropDownData] = useState<DropDownProps>(chartData)
  const [mainSeries, setMainSeries] = useState<MainSeriesProps>([])
  const [otherMarkColorArray, setOtherMarkColorArray] = useState<any[]>([])
  /* c8 ignore next 7 */
  const handleChange = (event: SelectChangeEvent) => {
    setCurrentColumns(event.target.value)
    const selectedId = chartData?.filter(
      (item) => item?.label === event.target.value,
    )
    setDropDownData(selectedId)
  }

  useEffect(() => {
    if (props?.reportId !== '') {
      const initialValue = chartData?.[0]?.label
      setCurrentColumns(initialValue)
      const selectedId = chartData?.filter(
        (item) => item?.label === initialValue,
      )
      setDropDownData(selectedId)
    }
  }, [props?.reportId])

  useEffect(() => {
    if (offset_seconds && dropDownData?.length > 0) {
      const result = compareArrays(dropDownData, rows)
      setOtherMarkColorArray(result)
      const seriesData = [totalData(rows), ...specificColumnData(result)]
      setMainSeries(seriesData)
    }
  }, [offset_seconds, dropDownData])

  /* c8 ignore next 10 */
  const slotsMark = (props: MarkElementProps) => {
    const totalMarkColor = rows?.[props?.dataIndex]['total-total']?.stat_test
    const otherMarkColor = otherMarkColorArray[props?.dataIndex]?.stat_test
    const { barColor: firstColor } = getBarColors(
      props?.id == 'Total' ? totalMarkColor : otherMarkColor,
    )
    return (
      <MarkElement {...props} color={firstColor} sx={{ fill: firstColor }} />
    )
  }

  return (
    <Grid2 sx={{ height: '100%' }}>
      <Card
        className="card-decoration"
        data-testid={'cross-tab-render'}
        sx={{ height: '100%' }}
      >
        <CardContent sx={{ backgroundColor: 'background.paper' }}>
          <Grid2 container spacing={2} sx={{ mb: 2 }}>
            <Grid2 size={{ xs: 12 }}>
              <Typography sx={{ fontSize: 16 }}>{data?.name}</Typography>
              {(data?.datasets || props.dataset) == 'simulated' && (
                <Alert severity="warning" sx={{ mt: 1 }}>
                  Simulated data
                </Alert>
              )}
            </Grid2>
          </Grid2>
          <Box
            sx={{
              height: '100% !important',
            }}
          >
            {offset_seconds && mainSeries?.length > 0 && (
              <LineChartGrid
                setChartWidth={setChartWidth}
                chartWidth={chartWidth}
                currentColumns={currentColumns}
                handleChange={handleChange}
                linesColors={LINE_COLORS}
                slotsMark={slotsMark}
                mainSeries={mainSeries}
                chartData={chartData}
                rows={rows}
              />
            )}
            {!offset_seconds && (
              <DataTableGrid
                type="report-popup"
                rows={rows}
                columns={columns}
                reportId={props?.reportId}
                dataset={props?.dataset}
                columnGroupingModel={columnGroupingModel}
                pinnedRows={pinnedRows}
                rowGroupingModel={rowGroupingModel}
                setRowGroupingModel={setRowGroupingModel}
                pinnedColumnsData={pinnedColumnsData}
                source={source}
              />
            )}
          </Box>
          {data?.insights && (
            <Markdown className="markdown">{data?.insights}</Markdown>
          )}
        </CardContent>
      </Card>
    </Grid2>
  )
}

export default ReportRenderer
