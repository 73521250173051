import { Monaco } from '@monaco-editor/react'
import { editor, IDisposable } from 'monaco-editor'
import { Identifier, RaRecord } from 'react-admin'
import React from 'react'
import { getAuth } from '../../Providers/AuthProvider'
import { isGreaterThanOneDay } from '../../utils/index'
import { Mx8Theme, mx8DarkThemeMonaco } from '../../layout'
import {
  ErrorArray,
  ErrorItem,
  CodeSaveInLocalstorageInterface,
  HandleDidMountInterface,
} from './types'
import { ThemeColors } from '../types'
import * as Sentry from '@sentry/react'

/**
 *
 * @param editor - the monaco editor instance
 * @param monaco - the monaco instance
 * @param codeErrors - the error array
 * @returns
 */

export const addErrorMarkers = (
  monaco: Monaco | undefined,
  editor: editor.IStandaloneCodeEditor | undefined,
  codeErrors: ErrorArray,
) => {
  if (!monaco) {
    return
  }
  const model = editor?.getModel()
  if (!model) {
    return
  }

  if (codeErrors.length == 0) {
    // Clear all decorations and markers
    editor?.createDecorationsCollection([])
    monaco.editor.setModelMarkers(model, 'my-source', [])
  } else {
    // Parse the error collection and add markers and decorations
    monaco.editor.setModelMarkers(
      model,
      'my-source',
      codeErrors.map((error: ErrorItem) => ({
        severity: monaco.MarkerSeverity.Error,
        message: error.message,
        startLineNumber: error.lineNumber,
        startColumn: 1,

        endLineNumber: error.lineNumber,
        endColumn: 25,
      })),
    )

    editor?.createDecorationsCollection(
      codeErrors.map((error: ErrorItem) => ({
        range: new monaco.Range(error.lineNumber, 1, error.lineNumber, 50),
        options: {
          isWholeLine: true,
          className: 'survey_editor_error',
        },
      })),
    )
  }
}

/**
 * Parse a FieldError object into an array of ErrorItem
 * @param error - FieldError object
 * @returns - Array of ErrorItem
 */
export const parseFieldError = (error: string | undefined): ErrorArray => {
  // decode the JSON string
  if (error === undefined) return []
  let errorArray: never[] = []
  try {
    errorArray = JSON.parse(error)
  } catch (e) {
    return []
  }

  return Object.entries(errorArray).map(([line, message]) => ({
    lineNumber: parseInt(line),
    message: message as string,
  }))
}

/**
 * Parse an error record into an array of ErrorItem
 * @param record - Error record
 * @returns - Array of ErrorItem
 */
export const parseErrorRecord = (record: RaRecord): ErrorArray => {
  if (!record?.errors) {
    return []
  }

  // take records of the form {lineNumber: message} and return as array of ErrorItem
  return Object.keys(record.errors)
    .map((key) => ({
      lineNumber: parseInt(key),
      message: record?.errors[key] as string,
    }))
    .filter((error) => error.message)
}

export const handleChange = (
  field: { onChange: (value: string) => void },
  value: string,
  id: number | string | undefined,
  codePathname: string,
) => {
  const updated_at = new Date()
  field.onChange(value)
  const codeValue = {
    id: id,
    code: value,
    updated_at,
  }
  // set the value in local storage as a string
  if (codePathname !== 'code') return
  localStorage.setItem('code', JSON.stringify(codeValue))
}
export const suggestionsApiCall = async () => {
  try {
    const AUTH_TOKEN = await getAuth().auth0Client.getTokenSilently()
    /* c8 ignore next 1*/
    const api_protocol = import.meta.env.VITE_MX8_ADMIN_API_PROTOCOL ?? 'https'

    const response = await fetch(
      `${api_protocol}://${import.meta.env.VITE_MX8_ADMIN_API_DOMAIN}/v1/completions`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${AUTH_TOKEN}`,
          'Content-Type': 'application/json',
        },
      },
    )

    if (!response.ok) {
      throw new Error(`API request failed with status ${response.status}`)
    }

    const suggestionsData = await response.json()
    return { suggestions: suggestionsData }
  } catch (error) {
    Sentry.captureException(error)
    return { suggestions: [] }
  }
}
export const handleDidMountFun = async (
  HandleDidMountProps: HandleDidMountInterface,
) => {
  const { monaco, editor, setDisposeVal, source, lineNumber } =
    HandleDidMountProps
  const registerCompletionProvider = async () => {
    const provider = monaco.languages.registerCompletionItemProvider('python', {
      /* c8 ignore next 3*/
      provideCompletionItems: async () => {
        return await suggestionsApiCall()
      },
    })
    setDisposeVal(provider)
  }
  if (source === 'code') {
    await registerCompletionProvider()
  }

  if (lineNumber) {
    editor.setPosition({ lineNumber, column: 1000 })
    editor.revealLineInCenterIfOutsideViewport(lineNumber)
  }
}

export const errorMakersFun = (
  inputErrorMessage: string,
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
  instanceMonaco: Monaco,
  instanceEditor: editor.IStandaloneCodeEditor,
  codeErrors: ErrorArray,
) => {
  if (inputErrorMessage) {
    const fieldError = parseFieldError(inputErrorMessage)
    if (fieldError.length > 0) {
      codeErrors = fieldError
    } else {
      setErrorMessage(inputErrorMessage)
    }
    if (instanceMonaco && instanceEditor) {
      addErrorMarkers(instanceMonaco, instanceEditor, codeErrors)
    }
  }
}

// for selecting the survey code value using modal
export const handleSelect = (
  value: 'save' | 'discard',
  setShowDialog: React.Dispatch<React.SetStateAction<boolean>>,
  setEditorValue: React.Dispatch<React.SetStateAction<string>>,
  source: string,
  record: RaRecord<Identifier> | undefined,
  code: string,
) => {
  if (value === 'save') {
    // select the locally stored value when user selects save
    setEditorValue(code)
  } else if (value === 'discard') {
    // select the received code value when user selects discard
    setEditorValue(record?.[source])
  }
  setShowDialog(false)

  localStorage.removeItem('code')
}
// survey id of local storage and survey id received data matches

export const codeSaveInLocalstorage = (
  codePassValue: CodeSaveInLocalstorageInterface,
) => {
  const {
    record,
    codeValue,
    codePathname,
    currentTime,
    setEditorValue,
    setShowDialog,
    fieldValue,
    editorValue,
    props,
  } = codePassValue
  if (record?.id === codeValue?.id && codePathname === 'code') {
    if (editorValue !== record?.code) {
      return
      /* c8 ignore next 11 */
    } else if (codeValue?.code === record?.code) {
      // local storage code matches with received code set locally stored code
      setEditorValue(codeValue?.code)
    } else if (!isGreaterThanOneDay(codeValue?.updated_at, currentTime)) {
      // if the difference b/w timestamp locally stored code and current time is not greater than one day than show the dialog to ask user to select between locally stored code and received code
      setShowDialog(true)
    } else if (isGreaterThanOneDay(codeValue?.updated_at, currentTime)) {
      // if the difference b/w timestamp locally stored code and current time is greater than one day than remove the code from local storage
      localStorage.removeItem('code')
      setEditorValue(record[props.source])
    }
  } else if (props.readOnly) {
    setEditorValue(record?.[props.source])
  } else if (fieldValue === '') {
    const val = props?.defaultValue ?? ''
    setEditorValue(val)
  } else {
    setEditorValue(fieldValue)
  }
}

export const DisposeFunc = (source: string, disposeVal: IDisposable) => {
  if (source === 'code') {
    return () => {
      disposeVal?.dispose()
    }
  }
}

// Common structure for the Monaco theme rules and colors
/* c8 ignore next 118 */
const createMonacoTheme = (
  mode: 'light' | 'dark',
  colors: ThemeColors,
): editor.IStandaloneThemeData => {
  return {
    base: mode === 'dark' ? 'vs-dark' : 'vs',
    inherit: false,
    rules: [
      { token: '', background: colors.background },
      { token: 'comment', foreground: colors.comment },
      { token: 'string', foreground: colors.string },
      { token: 'constant.language', foreground: colors.constantLanguage },
      { token: 'keyword', foreground: colors.keyword },
      { token: 'storage', foreground: colors.storage },
      { token: 'entity.name.type', foreground: colors.entityNameType },
      {
        token: 'entity.name.function',
        foreground: colors.entityNameFunction ?? '',
      },
      { token: 'support.function', foreground: colors.supportFunction },
      { token: 'support.constant', foreground: colors.supportConstant },
      { token: 'support.type', foreground: colors.supportType },
      { token: 'support.class', foreground: colors.supportClass },
      { token: 'support.variable', foreground: colors.supportVariable },
      {
        token: 'invalid',
        foreground: colors.invalidForeground ?? '',
        background: colors.invalidBackground ?? '',
      },
      {
        token: 'constant.other.placeholder.py',
        foreground: colors.placeholder ?? '',
      },
    ],
    colors: {
      'editor.foreground': colors.editorForeground ?? '',
      'editor.background': colors.editorBackground ?? '',
      'editor.selectionBackground': colors.selectionBackground ?? '',
      'editor.lineHighlightBackground': colors.lineHighlightBackground ?? '',
      'editorCursor.foreground': colors.cursorForeground ?? '',
      'editorWhitespace.foreground': colors.whitespaceForeground ?? '',
      'editorLineNumber.foreground': colors.lineNumberForeground ?? '',
      'editorLineNumber.activeForeground':
        colors.activeLineNumberForeground ?? '',
    },
  }
}

// Define color values for the light theme
const lightThemeColors = {
  background: Mx8Theme.palette.text.secondary,
  comment: Mx8Theme.palette.secondary.main,
  string: Mx8Theme.palette.text.primary,
  constantLanguage: Mx8Theme.palette.secondary.main,
  keyword: Mx8Theme.palette.text.primary,
  storage: Mx8Theme.palette.divider,
  entityNameType: Mx8Theme.palette.primary.main,
  entityNameFunction: Mx8Theme.palette.primary.main,
  supportFunction: Mx8Theme.palette.secondary.main,
  supportConstant: Mx8Theme.palette.secondary.main,
  supportType: Mx8Theme.palette.secondary.main,
  supportClass: Mx8Theme.palette.secondary.main,
  supportVariable: Mx8Theme.palette.secondary.main,
  invalidForeground: Mx8Theme.palette.background.paper,
  invalidBackground: Mx8Theme.palette.error.main,
  placeholder: Mx8Theme.palette.error.main,
  editorForeground: Mx8Theme.palette.primary.main,
  editorBackground: Mx8Theme.palette.background.paper,
  selectionBackground: Mx8Theme.palette.secondary.light,
  lineHighlightBackground: Mx8Theme.palette.divider,
  cursorForeground: Mx8Theme.palette.text.primary,
  whitespaceForeground: Mx8Theme.palette.divider,
  lineNumberForeground: Mx8Theme.palette.primary.main,
  activeLineNumberForeground: Mx8Theme.palette.divider,
}

// Define color values for the dark theme
export const darkThemeColors = {
  background: mx8DarkThemeMonaco.background,
  comment: mx8DarkThemeMonaco.comment,
  string: mx8DarkThemeMonaco.string,
  constantLanguage: mx8DarkThemeMonaco.constantLanguage,
  keyword: mx8DarkThemeMonaco.keyword,
  storage: mx8DarkThemeMonaco.storage,
  entityNameType: mx8DarkThemeMonaco.entityNameType,
  entityNameFunction: mx8DarkThemeMonaco.entityNameFunction,
  supportFunction: mx8DarkThemeMonaco.supportFunction,
  supportConstant: mx8DarkThemeMonaco.supportConstant,
  supportType: mx8DarkThemeMonaco.supportType,
  supportClass: mx8DarkThemeMonaco.supportClass,
  supportVariable: mx8DarkThemeMonaco.supportVariable,
  invalidForeground: mx8DarkThemeMonaco.invalidForeground,
  invalidBackground: mx8DarkThemeMonaco.invalidBackground,
  placeholder: mx8DarkThemeMonaco.placeholder,
  editorForeground: mx8DarkThemeMonaco.editorForeground,
  editorBackground: mx8DarkThemeMonaco.editorBackground,
  selectionBackground: mx8DarkThemeMonaco.selectionBackground,
  lineHighlightBackground: mx8DarkThemeMonaco.lineHighlightBackground,
  cursorForeground: mx8DarkThemeMonaco.cursorForeground,
  whitespaceForeground: mx8DarkThemeMonaco.whitespaceForeground,
  lineNumberForeground: mx8DarkThemeMonaco.lineNumberForeground,
  activeLineNumberForeground: mx8DarkThemeMonaco.activeLineNumberForeground,
}

// Export the light and dark themes
export const monacoTheme = createMonacoTheme('light', lightThemeColors)
export const monacoDarkTheme = createMonacoTheme('dark', darkThemeColors)
