import { Grid2 } from '@mui/material'
import {
  AutocompleteArrayInput,
  maxLength,
  TextInput,
  useGetList,
  useRecordContext,
} from 'react-admin'
import { ReportEditorProps } from '../types'
import ReportSurveyEdit from './ReportSurveyEdit'

const ReportEditor = (props: ReportEditorProps) => {
  const report = useRecordContext()
  const { data } = useGetList('report_questions', {
    filter: { report_id: report?.id },
    sort: { field: 'sort_order', order: 'ASC' },
    pagination: { page: 1, perPage: 100 },
  })
  /* c8 ignore next 1 */
  const QuestionDropdownList = data && data?.length > 0 ? data : []
  return (
    <Grid2
      container
      spacing={2}
      data-testid="report-editor"
      sx={{ width: '100%' }}
    >
      <Grid2 size={{ xs: 12 }} sx={{ paddingLeft: 0 }}>
        <TextInput
          source="name"
          fullWidth
          id="report_name"
          name="name"
          validate={[maxLength(200)]}
        />
      </Grid2>
      <Grid2 size={{ xs: 12 }} sx={{ paddingLeft: 0 }}>
        {/* c8 ignore next */}
        {props.hideSurvey && <ReportSurveyEdit />}
      </Grid2>
      <Grid2 size={{ xs: 12, md: 6 }}>
        <AutocompleteArrayInput
          label="Questions"
          source="selected_questions"
          optionText="reporting_label"
          optionValue="id"
          choices={QuestionDropdownList}
          defaultValue={report?.selected_questions}
        />
      </Grid2>
    </Grid2>
  )
}

export default ReportEditor
