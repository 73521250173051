import { TemplateData } from './types'

export const sampleFields = [
  {
    name: 'complete',
    value: 'https://test.com/complete',
  },
  {
    name: 'terminated',
    value: 'https://test.com/terminated',
  },
  {
    name: 'refused_consent',
    value: 'https://test.com/refused_consent',
  },
  {
    name: 'quota_screenout',
    value: 'https://test.com/quota_screenout',
  },
  {
    name: 'quota_overquota',
    value: 'https://test.com/quota_overquota',
  },
  {
    name: 'poor_quality',
    value: 'https://test.com/poor_quality',
  },
  {
    name: 'duplicate_respondent',
    value: 'https://test.com/duplicate_respondent',
  },
  {
    name: 'invalid_url',
    value: 'https://test.com/invalid_url',
  },
]

export const NO_CREDIT_MSG = 'Not enough credit to put this audience live'
export const ERR_UPDATE_MSG =
  'Something was wrong with the update. Please review the errors and give it another try.'

export const TypeFieldMap = [
  ['Panel', 'panel'],
  ['First Party', 'first_party'],
  ['Simulated', 'test'],
  ['Bring your own sample', 'basic'],
]

export const TypeFieldMapWithoutPanel = [
  ['First Party', 'first_party'],
  ['Simulated', 'test'],
  ['Bring your own sample', 'basic'],
]

export const AdvancedAudiencesInputMap = [
  ['Panel', 'panel'],
  ['First Party', 'first_party'],
  ['Bring your own sample', 'basic'],
]

export const CoreAudiencesInputMap = [['Panel', 'panel']]

// data for language
export const LanguageMap: [string, string][] = [
  ['Arabic', 'ar'],
  ['Bengali Bangla', 'be'],
  ['Bulgarian', 'bg'],
  ['Burmese', 'my'],
  ['Chinese Simplified', 'zh'],
  ['Croatian', 'hr'],
  ['Czech', 'cs'],
  ['Danish', 'da'],
  ['Dutch', 'nl'],
  ['English', 'en-us'],
  ['British English', 'en-gb'],
  ['Finnish', 'fi'],
  ['French', 'fr'],
  ['German', 'de'],
  ['Greek', 'el'],
  ['Hebrew', 'he'],
  ['Hindi', 'hi'],
  ['Hungarian', 'hu'],
  ['Indonesian', 'id'],
  ['Italian', 'it'],
  ['Japanese', 'ja'],
  ['Kazakh', 'kk'],
  ['Khmer', 'km'],
  ['Korean', 'ko'],
  ['Lithuanian', 'lt'],
  ['Malay', 'ms'],
  ['Norwegian', 'nb'],
  ['Polish', 'pl'],
  ['Portuguese', 'pt'],
  ['Punjabi', 'pa'],
  ['Romanian', 'ro'],
  ['Russian', 'ru'],
  ['Serbian', 'sr'],
  ['Slovak', 'sk'],
  ['Slovenian', 'sl'],
  ['Spanish', 'es'],
  ['Swedish', 'sv'],
  ['Thai', 'th'],
  ['Turkish', 'tr'],
  ['Ukrainian', 'uk'],
  ['Urdu', 'ur'],
  ['Uzbek', 'uz'],
  ['Vietnamese', 'vi'],
]
// data for country
export const CountryMap: [string, string][] = [
  ['Argentina', 'AR'],
  ['Australia', 'AU'],
  ['Austria', 'AT'],
  ['Bangladesh', 'BD'],
  ['Belgium', 'BE'],
  ['Brazil', 'BR'],
  ['Bulgaria', 'BG'],
  ['Cambodia', 'KH'],
  ['Canada', 'CA'],
  ['Chile', 'CL'],
  ['China', 'CN'],
  ['Colombia', 'CO'],
  ['Costa Rica', 'CR'],
  ['Croatia', 'HR'],
  ['Czech Republic', 'CZ'],
  ['Denmark', 'DK'],
  ['Ecuador', 'EC'],
  ['Egypt', 'EG'],
  ['Finland', 'FI'],
  ['France', 'FR'],
  ['Germany', 'DE'],
  ['Greece', 'GR'],
  ['Hong Kong', 'HK'],
  ['Hungary', 'HU'],
  ['India', 'IN'],
  ['Indonesia', 'ID'],
  ['Ireland', 'IE'],
  ['Israel', 'IL'],
  ['Italy', 'IT'],
  ['Japan', 'JP'],
  ['Kazakhstan', 'KZ'],
  ['Kuwait', 'KW'],
  ['Lebanon', 'LB'],
  ['Lithuania', 'LT'],
  ['Malaysia', 'MY'],
  ['Mexico', 'MX'],
  ['Myanmar', 'MM'],
  ['Netherlands', 'NL'],
  ['New Zealand', 'NZ'],
  ['Nigeria', 'NG'],
  ['Norway', 'NO'],
  ['Pakistan', 'PK'],
  ['Peru', 'PE'],
  ['Philippines', 'PH'],
  ['Poland', 'PL'],
  ['Portugal', 'PT'],
  ['Puerto Rico', 'PR'],
  ['Qatar', 'QA'],
  ['Romania', 'RO'],
  ['Russia', 'RU'],
  ['Saudi Arabia', 'SA'],
  ['Serbia', 'RS'],
  ['Singapore', 'SG'],
  ['Slovakia', 'SK'],
  ['Slovenia', 'SI'],
  ['South Africa', 'ZA'],
  ['South Korea', 'KR'],
  ['Spain', 'ES'],
  ['Sweden', 'SE'],
  ['Switzerland', 'CH'],
  ['Taiwan', 'TW'],
  ['Thailand', 'TH'],
  ['Turkey', 'TR'],
  ['United Kingdom', 'GB'],
  ['Ukraine', 'UA'],
  ['United Arab Emirates', 'AE'],
  ['United States', 'US'],
  ['Uzbekistan', 'UZ'],
  ['Vietnam', 'VN'],
]

export const UsaGenPop = `{
  "Age": {
    "18-24": 0.12,
    "25-34": 0.18,
    "35-44": 0.17,
    "45-54": 0.16,
    "55-64": 0.17,
    "65-99": 0.20
  },
  "Ethnicity": {
    "White": 0.75,
    "Black": 0.14,
    "Other": 0.11
  },
  "Hispanic": {
    "Yes": 0.19,
    "No": 0.81
  },
  "Gender": {
    "Male": 0.49,
    "Female": 0.51
  },
  "Income": {
    "0-24999": 0.16,
    "25000-49999": 0.19,
    "50000-99999": 0.28,
    "100000-149999": 0.16,
    "150000-500000": 0.21
  },
  "Region": {
      "Northeast": 0.18,
      "Midwest": 0.23,
      "South": 0.37,
      "West": 0.22}
}`

export const UsNestedGenPop = `{
  "Age": {
    "18-24": 0.12,
    "25-34": 0.18,
    "35-44": 0.17,
    "45-54": 0.16,
    "55-64": 0.17,
    "65-99": 0.2
  },
  "Ethnicity": {
    "White": {
      "Hispanic": {
        "Yes": 0.18,
        "No": 0.58
      }
    },
    "Black": {
      "Hispanic": {
        "Yes": 0.01,
        "No": 0.13
      }
    },
    "Other": {
      "Hispanic": {
        "No": 0.1
      }
    }
  },
  "Hispanic": {
    "Yes": 0.19,
    "No": 0.81
  },
  "Gender": {
    "Male": {
      "Age": {
        "18-24": 0.06,
        "25-34": 0.09,
        "35-44": 0.085,
        "45-54": 0.08,
        "55-64": 0.085,
        "65-99": 0.09
      }
    },
    "Female": {
      "Age": {
        "18-24": 0.06,
        "25-34": 0.09,
        "35-44": 0.085,
        "45-54": 0.08,
        "55-64": 0.085,
        "65-99": 0.11
      }
    }
  },
  "Income": {
    "0-24999": {
      "Gender": {
        "Male": 0.08,
        "Female": 0.09
      }
    },
    "25000-49999": {
      "Gender": {
        "Male": 0.09,
        "Female": 0.1
      }
    },
    "50000-99999": {
      "Gender": {
        "Male": 0.14,
        "Female": 0.14
      }
    },
    "100000-149999": {
      "Gender": {
        "Male": 0.08,
        "Female": 0.08
      }
    },
    "150000-500000": {
      "Gender": {
        "Male": 0.1,
        "Female": 0.1
      }
    }
  },
  "Region": {
    "Northeast": 0.18,
    "Midwest": 0.23,
    "South": 0.37,
    "West": 0.22
  }
}`

export const Australia = `{
  "Age": {
    "18-24": 0.12,
    "25-34": 0.19,
    "35-44": 0.17,
    "45-54": 0.16,
    "55-64": 0.15,
    "65-99": 0.21
  },
  "Gender": {
    "Male": 0.49,
    "Female": 0.51
  },
  "Income": {
    "0-14999": 0.06,
    "15000-24999": 0.07,
    "25000-49999": 0.2,
    "50000-74999": 0.17,
    "75000-149999": 0.3,
    "150000+": 0.2
  },
  "Location": {
    "Victoria": 0.25,
    "Queensland": 0.2,
    "Tasmania": 0.02,
    "Australian Capital Territory": 0.02,
    "Northern Territory": 0.01,
    "South Australia": 0.07,
    "New South Wales": 0.32,
    "Western Australia": 0.11
  }
}`

export const UkGenPop = `{
    "Age": {
        "18-24": 0.11,
        "25-34": 0.17,
        "35-44": 0.16,
        "45-54": 0.18,
        "55-64": 0.15,
        "65+": 0.23
    },
    "Gender": {
        "Male": 0.49,
        "Female": 0.51
    },
    "Household Income": {
        "0-19999": 0.26,
        "20000-29999": 0.19,
        "30000-49999": 0.25,
        "50000-69999": 0.14,
        "70000+": 0.16
    },
    "Region": {
        "London": 0.13,
        "South East": 0.14,
        "East of England": 0.09,
        "South West": 0.09,
        "North West": 0.11,
        "East Midlands": 0.08,
        "West Midlands": 0.09,
        "Yorkshire and the Humber": 0.08,
        "North East": 0.04,
        "Wales": 0.04,
        "Scotland": 0.08,
        "Northern Ireland": 0.03
    }
}`

export const Uk16to54 = `{
    "Age": {
        "16-24": 0.22,
        "25-34": 0.26,
        "35-44": 0.26,
        "45-54": 0.26
    },
    "Gender": {
        "Male": 0.49,
        "Female": 0.51
    },
    "Household Income": {
        "0-19999": 0.26,
        "20000-29999": 0.19,
        "30000-49999": 0.25,
        "50000-69999": 0.14,
        "70000+": 0.16
    },
    "Region": {
        "London": 0.13,
        "South East": 0.14,
        "East of England": 0.09,
        "South West": 0.09,
        "North West": 0.11,
        "East Midlands": 0.08,
        "West Midlands": 0.09,
        "Yorkshire and the Humber": 0.08,
        "North East": 0.04,
        "Wales": 0.04,
        "Scotland": 0.08,
        "Northern Ireland": 0.03
    }
}`

export const templateData: TemplateData[] = [
  {
    name: 'US Genpop',
    id: '1',
    audienceName: 'US Genpop',
    language: 'en-us',
    country: 'US',
    json_audience: UsaGenPop,
  },
  {
    name: 'US Nested Genpop',
    id: '2',
    audienceName: 'US Nested Genpop',
    language: 'en-us',
    country: 'US',
    json_audience: UsNestedGenPop,
  },
  {
    name: 'Australia',
    id: '3',
    audienceName: 'Australia',
    language: 'en-au',
    country: 'AU',
    json_audience: Australia,
  },
  {
    name: 'Uk GenPop',
    id: '4',
    audienceName: 'UkGenPop',
    language: 'en-gb',
    country: 'GB',
    json_audience: UkGenPop,
  },
  {
    name: 'Uk 16 to 54',
    id: '5',
    audienceName: 'Uk16to54',
    language: 'en-gb',
    country: 'GB',
    json_audience: Uk16to54,
  },
]
