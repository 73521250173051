import { Box, Checkbox, Typography } from '@mui/material'
import { FC, MouseEvent, useState } from 'react'
import { Link, useNotify } from 'react-admin'
import { CheckBoxComponentProps } from '../../../types'
import * as Sentry from '@sentry/react'

const CheckBoxList: FC<CheckBoxComponentProps> = ({ idx, onChange, todo }) => {
  const [checked, setChecked] = useState(todo.completed)
  const notify = useNotify()

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    const target = event.target as HTMLInputElement
    if (todo.clickable) {
      setChecked(target.checked)
      onChange(target.checked, todo)
    } else if (!todo.clickable && !todo.completed) {
      Sentry.captureMessage(`${todo.error_message}`, 'info')
      notify(todo.error_message, { type: 'error' })
    }
  }
  return (
    <Box paddingBottom="8px" display="flex" alignItems="start">
      <Checkbox
        data-testid={`todo-checkbox-${idx}`}
        sx={{ padding: '0', '& svg': { color: 'primary.main' } }}
        checked={checked}
        onClick={handleClick}
      />
      <Box
        sx={{
          paddingLeft: '9px',
          textDecoration: checked ? 'line-through' : null,
          textDecorationThickness: '1px',
        }}
      >
        {todo?.path && !checked ? (
          <Link
            sx={{
              color: 'primary.main',
              fontSize: '11pt',
              fontWeight: '600',
              textDecoration: 'underLine',
              textDecorationColor: 'text.primary',
              fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            }}
            to={todo?.path}
          >
            {todo?.heading}:
          </Link>
        ) : (
          <Typography
            sx={{
              color: 'text.primary',
              fontSize: '11pt',
              fontWeight: checked ? null : '600',
              letterSpacing: '0',
            }}
            variant="caption"
          >
            {todo?.heading}:
          </Typography>
        )}
        <Typography variant="caption" fontSize="11pt">
          {' '}
          {todo?.description}
        </Typography>
      </Box>
    </Box>
  )
}

export default CheckBoxList
