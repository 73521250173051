import { Grid2, Stack } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { required, SelectInput, TextInput, useRecordContext } from 'react-admin'
import { themeData } from '../../../../utils/constants'
import { ThemeType } from '../../types'
import { resolveImageUrl } from '../../../../utils'
import { ChatPreview, ColorPicker } from './components'

const SurveyTheme: FC = () => {
  const survey = useRecordContext()
  const [customTheme, setCustomTheme] = useState<ThemeType>(themeData)

  /* c8 ignore next */
  const updating = !['Draft', 'Complete', 'Error', 'In Field'].includes(
    survey?.status,
  )
  const fielding = survey?.status === 'In Field'

  useEffect(() => {
    const background_image = resolveImageUrl(survey?.theme?.background_image)
    const icon_image = resolveImageUrl(survey?.theme?.icon_image)
    setCustomTheme({ ...survey?.theme, background_image, icon_image })
  }, [survey])

  return (
    <Grid2 spacing={5} container width="100%" data-testid="parent-theme">
      <Grid2 size={{ sm: 3, xs: 12 }}>
        <Stack>
          <SelectInput
            id="select-theme"
            source={'theme.orientation'}
            fullWidth
            className="white-bg"
            disabled={updating || fielding}
            defaultValue={survey?.theme?.orientation}
            label="Orientation"
            choices={[
              { id: 'portrait', name: 'Portrait' },
              { id: 'landscape', name: 'Landscape' },
            ]}
            validate={required()}
            /* c8 ignore next 6 */
            onChange={(e) =>
              setCustomTheme((prev) => ({
                ...prev,
                orientation: e.target.value,
              }))
            }
          />
          <ColorPicker
            disabled={updating || fielding}
            label="Main"
            themeColor="main_color"
            color={customTheme?.main_color}
            setCustomTheme={setCustomTheme}
          />
          <ColorPicker
            disabled={updating || fielding}
            label="Secondary"
            themeColor="secondary_color"
            color={customTheme?.secondary_color}
            setCustomTheme={setCustomTheme}
          />
          <ColorPicker
            disabled={updating || fielding}
            label="Error"
            themeColor="error_color"
            color={customTheme?.error_color}
            setCustomTheme={setCustomTheme}
          />
          <ColorPicker
            disabled={updating || fielding}
            label="Accent Color"
            themeColor="accent_color"
            color={customTheme?.accent_color}
            setCustomTheme={setCustomTheme}
          />
          <TextInput
            source="theme.icon_image"
            label="Icon"
            disabled={updating || fielding}
            value={customTheme?.icon_image}
            /* c8 ignore next 6 */
            onChange={(e) =>
              setCustomTheme((prev) => ({
                ...prev,
                icon_image: resolveImageUrl(e.target.value),
              }))
            }
          />
          <TextInput
            source="theme.background_image"
            label="Background"
            disabled={updating || fielding}
            value={customTheme?.background_image}
            /* c8 ignore next 6 */
            onChange={(e) =>
              setCustomTheme((prev) => ({
                ...prev,
                background_image: resolveImageUrl(e.target.value),
              }))
            }
          />
          <TextInput
            source="theme.line_1"
            label="Line 1"
            disabled={updating || fielding}
            /* c8 ignore next 3 */
            onChange={(e) =>
              setCustomTheme((prev) => ({ ...prev, line_1: e.target.value }))
            }
          />
          <TextInput
            source="theme.line_2"
            label="Line 2"
            disabled={updating || fielding}
            /* c8 ignore next 3 */
            onChange={(e) =>
              setCustomTheme((prev) => ({ ...prev, line_2: e.target.value }))
            }
          />
          <TextInput
            source="theme.hints.waiting"
            label="Waiting"
            disabled={updating || fielding}
            /* c8 ignore next 6 */
            onChange={(e) =>
              setCustomTheme((prev) => ({
                ...prev,
                hints: { ...prev.hints, waiting: e.target.value },
              }))
            }
          />
          <TextInput
            source="theme.hints.multichoice"
            label="Multichoice"
            disabled={updating || fielding}
            /* c8 ignore next 6 */
            onChange={(e) =>
              setCustomTheme((prev) => ({
                ...prev,
                hints: { ...prev.hints, multichoice: e.target.value },
              }))
            }
          />
          <TextInput
            source="theme.hints.multiselect"
            label="Multiselect"
            disabled={updating || fielding}
            /* c8 ignore next 6 */
            onChange={(e) =>
              setCustomTheme((prev) => ({
                ...prev,
                hints: { ...prev.hints, multiselect: e.target.value },
              }))
            }
          />
          <TextInput
            source="theme.hints.others"
            label="Others"
            disabled={updating || fielding}
            /* c8 ignore next 6 */
            onChange={(e) =>
              setCustomTheme((prev) => ({
                ...prev,
                hints: { ...prev.hints, others: e.target.value },
              }))
            }
          />
        </Stack>
      </Grid2>
      <Grid2 size={{ sm: 9, xs: 12 }}>
        <ChatPreview customTheme={customTheme} />
      </Grid2>
    </Grid2>
  )
}

export default SurveyTheme
