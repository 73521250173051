import * as Sentry from '@sentry/react'
import { getAuth } from '../../../../../../Providers/AuthProvider'
/* c8 ignore next 2 */
const API_BASE_URL = `${import.meta.env.VITE_MX8_SURVEY_PROTOCOL ?? 'https'}://${import.meta.env.VITE_MX8_ADMIN_API_DOMAIN}`

// Generic GET request function
export const getApi = async <T>(endpoint: string): Promise<T> => {
  try {
    const token = await getAuth()?.auth0Client?.getTokenSilently()

    /* c8 ignore next 4 */
    if (!token) {
      throw new Error('Bearer token not found!')
    }

    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })

    if (!response.ok) {
      throw new Error(`GET request failed: ${response?.statusText}`)
    }

    return (await response?.json()) as T
  } catch (error) {
    Sentry.captureException(error)
    console.error(`Error in GET ${endpoint}:`, error)
    throw error
  }
}

// Generic POST request function
export const postApi = async <T>(
  endpoint: string,
  body: unknown,
): Promise<T> => {
  try {
    const token = await getAuth().auth0Client.getTokenSilently()

    /* c8 ignore next 4 */
    if (!token) {
      throw new Error('Bearer token not found!')
    }

    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })

    if (!response?.ok) {
      Sentry.captureException(response?.statusText)
      throw new Error(`POST request failed: ${response?.statusText}`)
    }

    return (await response?.json()) as T
  } catch (error) {
    console.error(`Error in POST ${endpoint}:`, error)
    Sentry.captureException(error)
    throw error
  }
}
