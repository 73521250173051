type AudienceStatus =
  | 'Updating'
  | 'New'
  | 'In Field'
  | 'Paused'
  | 'Complete'
  | 'Error'

const commonForbiddenFields = [
  'project_id',
  'survey_id',
  'language',
  'type',
  'market',
]

const statusBasedForbiddenFields: Record<AudienceStatus, string[]> = {
  Updating: [
    ...commonForbiddenFields,
    'weight',
    'weighting_audience_id',
    'status',
    'target_responses',
    'days_in_field',
    'json_audience',
    'excel_sheet',
    'contacts',
    'respondent_id_field',
    'urls',
    'questions_list',
    'price',
  ],
  New: [...commonForbiddenFields],
  Error: [...commonForbiddenFields],
  'In Field': [
    ...commonForbiddenFields,
    'weight',
    'weighting_audience_id',
    'status',
    'target_responses',
    'dataset_id',
    'days_in_field',
  ],
  Paused: [...commonForbiddenFields, 'status', 'dataset_id'],
  Complete: [
    ...commonForbiddenFields,
    'status',
    'target_responses',
    'days_in_field',
    'json_audience',
    'excel_sheet',
    'contacts',
    'respondent_id_field',
    'urls',
    'questions_list',
    'price',
    'dataset_id',
  ],
}

export function canUpdateField(status: AudienceStatus, field: string): boolean {
  const forbiddenFields = statusBasedForbiddenFields[status] || []

  return forbiddenFields.includes(field)
}
