import { FieldValues } from 'react-hook-form'
import { DEFAULT_SURVEY } from './constants'
import React from 'react'
import { themeData } from '../../utils/constants'

export const postSave = (
  data: FieldValues,
  setConditionRender: React.Dispatch<React.SetStateAction<string>>,
  create: (arg0: string, arg1: { data: FieldValues }) => void,
  orientationMode: string,
) => {
  const oreintationBasisTheme = {
    theme:
      orientationMode == 'portrait'
        ? themeData
        : { ...themeData, orientation: 'landscape' },
  }
  const surveyData = { ...data, ...oreintationBasisTheme }

  /* c8 ignore next 2 */
  if (data.code === DEFAULT_SURVEY) {
    setConditionRender('default-code')
  } else {
    create('surveys', { data: surveyData })
  }
}
/* c8 ignore next 13 */
export const onSubmitFun = (
  data: FieldValues,
  postData: FieldValues,
  descriptionText: string,
  setConditionRender: React.Dispatch<React.SetStateAction<string>>,
  create: () => void,
  orientationMode: string,
) => {
  descriptionText?.length > 0
    ? postSave(postData, setConditionRender, create, orientationMode)
    : postSave(data, setConditionRender, create, orientationMode)
}
