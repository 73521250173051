import {
  Column,
  Media,
  ResponseForMediaDataTypes,
  Row,
  SetOpenModelUrlType,
} from '../../types'
import { getAuth } from '../../../../Providers/AuthProvider'
import { IFileWithMeta } from 'react-dropzone-uploader'
import * as Sentry from '@sentry/react'

export const addInMediaHook = (
  reponseForMedia: ResponseForMediaDataTypes,
  passMedia: Media,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (arg0: any) => void,
  file_remove: IFileWithMeta['remove'],
) => {
  const media = passMedia
  const new_row = media.columns.reduce((acc: Row, column: Column) => {
    return {
      ...acc,
      [column.name]: '',
    }
  }, {})

  // add the new media to the row
  new_row['thumbnail'] = reponseForMedia.thumbnail
  new_row['file_name'] = reponseForMedia.file_name
  new_row['security'] = true
  // remove any existing rows with the same filename....
  const initialColumns = [
    { name: 'thumbnail', type: 'thumbnail' },
    { name: 'file_name', type: 'file_name' },
    { name: 'security', type: 'security' },
  ]
  /* c8 ignore next 12 */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange((prev: any) => {
    const updateData = { ...prev }
    updateData.columns =
      updateData.rows.length >= 1 ? updateData.columns : initialColumns
    const updatedRows = updateData.rows.filter(
      (row: Row) => row['file_name'] !== reponseForMedia.file_name,
    )
    updateData.rows = [...updatedRows, new_row]
    return updateData // This updates the state correctly
  })
  file_remove()
  return true
}

export const imagevideoApi = async (
  file: IFileWithMeta['file'],
  value: Media,
  onChange: () => void,
  record_Survey_key: string,
  file_remove: IFileWithMeta['remove'],
  notify: NotifyFunction,
) => {
  /* c8 ignore next 3 */
  const token = await getAuth().auth0Client?.getTokenSilently()
  const apiProtocol = import.meta.env.VITE_MX8_ADMIN_API_PROTOCOL ?? 'https'
  const apiUrl = `${apiProtocol}://${import.meta.env.VITE_MX8_MEDIA_API_DOMAIN}/v1/media/${record_Survey_key}/${file.name}`

  if (file.type === 'video/mp4') {
    await handleVideoUpload(
      apiUrl,
      token,
      file,
      value,
      onChange,
      file_remove,
      notify,
    )
  } else {
    await handleImageUpload(
      apiUrl,
      token,
      file,
      value,
      onChange,
      file_remove,
      notify,
    )
  }
}

const handleVideoUpload = async (
  apiUrl: string,
  token: string | undefined,
  file: File,
  value: Media,
  onChange: () => void,
  file_remove: IFileWithMeta['remove'],
  notify: NotifyFunction,
) => {
  try {
    const response = await fetch(apiUrl, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    const reponseForMedia: ResponseForMediaDataTypes = await response.json()
    const formData = new FormData()
    if (reponseForMedia?.upload_form_data) {
      formData.append('key', reponseForMedia.upload_form_data.key)
      formData.append(
        'x-amz-signature',
        reponseForMedia.upload_form_data['x-amz-signature'],
      )
      formData.append(
        'x-amz-algorithm',
        reponseForMedia.upload_form_data['x-amz-algorithm'],
      )
      formData.append(
        'x-amz-date',
        reponseForMedia.upload_form_data['x-amz-date'],
      )
      formData.append(
        'x-amz-credential',
        reponseForMedia.upload_form_data['x-amz-credential'],
      )
      formData.append('policy', reponseForMedia.upload_form_data.policy)
      formData.append(
        'success_action_status',
        reponseForMedia.upload_form_data.success_action_status,
      )
      formData.append(
        'success_action_redirect',
        reponseForMedia.upload_form_data.success_action_redirect,
      )
      formData.append('file', file)
      if (reponseForMedia.upload_url) {
        const sThreeReponse = await fetch(reponseForMedia.upload_url, {
          method: 'POST',
          body: formData,
        })

        /* c8 ignore next 5 */
        if (sThreeReponse.status == 201) {
          notify('Video uploaded successfully !', { type: 'success' })
          addInMediaHook(reponseForMedia, value, onChange, file_remove)
        }
      }
    }
  } catch (error) {
    notify(
      'An unexpected error occurred! If this continues, please contact support.',
      { type: 'error' },
    )
    Sentry.captureException(error)
  }
}

const handleImageUpload = async (
  apiUrl: string,
  token: string | undefined,
  file: File,
  value: Media,
  onChange: () => void,
  file_remove: IFileWithMeta['remove'],
  notify: NotifyFunction,
) => {
  try {
    const ImageFille = new FormData()
    ImageFille.append('file', file)
    const response = await fetch(apiUrl, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: ImageFille,
    })

    const reponseForMedia = await response.json()
    if (reponseForMedia.thumbnail.length > 5) {
      addInMediaHook(reponseForMedia, value, onChange, file_remove)
      notify('Image Uploaded successfull !', { type: 'success' })
    }
    return reponseForMedia
  } catch (error) {
    notify(
      'An unexpected error occured. If this continues, please contact support.',
      { type: 'error' },
    )
    Sentry.captureException(error)
  }
}
type NotificationType = 'error' | 'success' | 'info'

type NotificationOptions = {
  type: NotificationType
}
type NotifyFunction = (message: string, options: NotificationOptions) => void

export const dropzoneMainFunc = (
  status: string,
  file: IFileWithMeta['file'] | null,
  value: Media | null,
  onChange: (() => void) | null,
  record_Survey_key: string | null,
  file_remove: IFileWithMeta['remove'] | null,
  notify: NotifyFunction | null,
) => {
  if (
    status === 'done' &&
    file &&
    value &&
    onChange &&
    record_Survey_key &&
    file_remove &&
    notify
  ) {
    imagevideoApi(file, value, onChange, record_Survey_key, file_remove, notify)
  } else if (status === 'rejected_file_type') {
    if (notify) {
      notify('Only Image (png & jpg)), Audio & Video allowed.', {
        type: 'error',
      })
    }
    Sentry.captureException({ type: 'error' })
  }
}

export const VideoViewFunc = async (
  file_name: string | number | boolean,
  record_Survey_key: string,
  setOpenModelUrl: SetOpenModelUrlType,
  notify: NotifyFunction,
) => {
  try {
    /* c8 ignore next 3 */
    const token = await getAuth().auth0Client?.getTokenSilently()
    const apiProtocol = import.meta.env.VITE_MX8_ADMIN_API_PROTOCOL ?? 'https'
    const apiUrl = `${apiProtocol}://${import.meta.env.VITE_MX8_MEDIA_API_DOMAIN}/v1/media/${record_Survey_key}/${file_name}`

    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.ok) {
      const playUrl = await response.json()
      setOpenModelUrl({
        playBackUrl: playUrl?.playback_url,
        modelOpen: true,
        conditionCheck: file_name as string,
      })
    } else {
      const playUrl = await response.json()
      notify(playUrl?.message, { type: 'error' })
    }
  } catch (error) {
    notify('Something went Wrong , please contact support.', { type: 'error' })
    Sentry.captureException(error)
  }
}
