import { ReportCreateTable } from '../pages/Reports/Create'
import { NavigatorProps } from '../utils/types'
import { ReportAnalysis, ReportEditor } from '../pages/Reports/components'
import ReportingGrid from './ReportingGrid'
import Navigator from './Navigator'

const ReportNavigator = (props: NavigatorProps) => {
  return (
    <Navigator
      reportResource="reports"
      reportType="report"
      reportLabel="Reports"
      customLabel="Create"
      reportNameField="name"
      ReportElement={ReportingGrid}
      EditElement={ReportEditor}
      AnalysisElement={ReportAnalysis}
      CreateElement={ReportCreateTable}
      {...props}
    />
  )
}

export default ReportNavigator
