// Insight Edit component

import { ListActionsProps } from '../../Translation/type'
import { successHandler } from '../../../utils'
import { EditInDialogButton } from '@react-admin/ra-form-layout'
import SubscriptionForm from '../SubscriptionForm'

export const SubscriptionEdit: React.FC<ListActionsProps> = ({ notify }) => {
  const onSuccess = successHandler('subscriptions', 'updated', 'list', notify)

  return (
    <EditInDialogButton
      resource="superuser/subscriptions"
      mutationMode="pessimistic"
      mutationOptions={{ onSuccess }}
      data-testid="subscriptions-edit"
      fullWidth
    >
      <SubscriptionForm />
    </EditInDialogButton>
  )
}
