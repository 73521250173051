import { FC, useEffect } from 'react'
import { ReportingDatasetSelectProps } from '../pages/Reports/types'
import { Labeled } from 'react-admin'
import { MenuItem, Select } from '@mui/material'

const ReportingDatasetSelect: FC<ReportingDatasetSelectProps> = (props) => {
  const { datasets, currentDataset, setCurrentDataset, shouldShowLoading } =
    props

  const defaultDataset = 'live'
  const id_valid =
    !!currentDataset && datasets[currentDataset] && !shouldShowLoading

  useEffect(() => {
    // If the current dataset is not in the list of possible datasets,
    // set the current dataset to the first possible dataset
    // or the default dataset if it is in the list of possible datasets
    if (Object.keys(datasets).length > 0 && !datasets[currentDataset]) {
      /* c8 ignore next 3 */
      if (datasets[defaultDataset]) {
        setCurrentDataset(defaultDataset)
      } else {
        setCurrentDataset(Object.keys(datasets)[0])
      }
    }
  }, [datasets, currentDataset, setCurrentDataset])

  const validValue = id_valid ? currentDataset : '0'

  return (
    <Labeled label="Dataset">
      <Select
        id="dataset-selector"
        fullWidth
        value={validValue}
        name="Dataset Selector"
        size="small"
        onChange={(event) => {
          setCurrentDataset(event.target.value)
        }}
        disabled={Object.keys(datasets).length == 0}
      >
        {id_valid ? (
          Object.entries(datasets).map(([key, value]) => (
            <MenuItem key={key} value={key}>
              {value}
            </MenuItem>
          ))
        ) : (
          <MenuItem key="0" value="0">
            Loading...
          </MenuItem>
        )}
      </Select>
    </Labeled>
  )
}

export default ReportingDatasetSelect
