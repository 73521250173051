import React from 'react'
import { App } from './App'
import { createRoot } from 'react-dom/client'
import './index.css'
import * as Sentry from '@sentry/react'

const SENTRY_ENVIRONMENT = import.meta.env.VITE_SENTRY_ENVIRONMENT as string
const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN as string

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [Sentry.browserProfilingIntegration()],
  environment: SENTRY_ENVIRONMENT,
  debug: true,
  tracesSampleRate: 1.0,
  replaysOnErrorSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  tracePropagationTargets: [
    /^http:\/\/localhost:8080\/.*/,
    /^https:\/\/dev\.mx8\.io\/.*/,
    /^https:\/\/stage\.mx8\.io\/.*/,
    /^https:\/\/app\.mx8\.io\/.*/,
  ],
})

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
