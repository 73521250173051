/* c8 ignore next 50 */
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-premium'
import { Mx8Theme } from '../../../../../../layout'
import { Box } from '@mui/system'
export const CustomToolbar = ({ source }: { source: string }) => (
  <GridToolbarContainer>
    {source !== 'cross_tab' ? (
      <>
        <GridToolbarExport
          slotProps={{
            button: { sx: { color: Mx8Theme.palette.primary.main } },
          }}
        />{' '}
        <GridToolbarDensitySelector
          slotProps={{
            button: { sx: { color: Mx8Theme.palette.primary.main } },
          }}
        />{' '}
      </>
    ) : (
      <>
        <GridToolbarColumnsButton
          slotProps={{
            button: { sx: { color: Mx8Theme.palette.primary.main } },
          }}
        />
        <GridToolbarFilterButton
          slotProps={{
            button: { sx: { color: Mx8Theme.palette.primary.main } },
          }}
        />
        <GridToolbarDensitySelector
          slotProps={{
            button: { sx: { color: Mx8Theme.palette.primary.main } },
          }}
        />
      </>
    )}

    <Box sx={{ flexGrow: 1 }} />
  </GridToolbarContainer>
)
