// Insight Edit component

import { ListActionsProps } from '../../Translation/type'
import { successHandler } from '../../../utils'
import { CreateInDialogButton } from '@react-admin/ra-form-layout'
import SubscriptionForm from '../SubscriptionForm'

export const SubscriptionCreate: React.FC<ListActionsProps> = ({ notify }) => {
  const onSuccess = successHandler('subscriptions', 'created', 'edit', notify)

  return (
    <CreateInDialogButton
      fullWidth
      resource="superuser/subscriptions"
      mutationOptions={{ onSuccess }}
      data-testid="subscriptions-create"
    >
      <SubscriptionForm />
    </CreateInDialogButton>
  )
}
