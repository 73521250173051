import {
  SimpleForm,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  NumberInput,
  TextField,
  FormDataConsumer,
  useGetOne,
  useUpdate,
  ResourceContextProvider,
} from 'react-admin'
import {
  Dialog,
  DialogTitle,
  Button,
  Box,
  Typography,
  Grid2,
} from '@mui/material'
import { useParams } from 'react-router'
import { FieldValues, SubmitHandler } from 'react-hook-form'

interface ReWeightingCompProps {
  open: boolean
  onClose: () => void
}

export const ReWeightingComp = ({ open, onClose }: ReWeightingCompProps) => {
  const { id } = useParams()
  const { data } = useGetOne(`surveys`, {
    id: `${id}/weighting`,
  })
  const [update] = useUpdate()

  const handleSubmit: SubmitHandler<FieldValues> = (formData) => {
    const payload = {
      id: formData?.id,
      datasets: [
        {
          key: formData?.dataset,
          name: formData?.dataset?.toUpperCase(),
          audiences: formData?.weighting,
        },
      ],
    }
    // Trigger the update request
    update(
      `surveys`,
      {
        id: `${formData.id}/weighting`,
        data: payload,
        previousData: data,
      },
      {
        /* c8 ignore next 3*/
        onSuccess: () => {
          onClose()
        },
        /* c8 ignore next 3*/
        onError: (error) => {
          console.error('Error updating weighting:', error)
        },
      },
    )
  }
  const weightingAudience = (audiences: { id: number; name: string }[]) => {
    /* c8 ignore next 4*/
    return audiences?.map((item: { id: number; name: string }) => ({
      id: item.id,
      name: item.name,
    }))
  }
  if (!data) return null

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle data-testid={'re-weighting-title'}>Re-weighting</DialogTitle>

      <ResourceContextProvider value="weighting">
        <SimpleForm toolbar={<></>} onSubmit={handleSubmit}>
          <Box width="100%" sx={{ paddingLeft: 2 }}>
            {/* Dataset Name */}
            <Grid2 container>
              <Grid2 size={{ xs: 12, sm: 8 }}>
                <SelectInput
                  fullWidth
                  source="dataset"
                  label="Dataset"
                  defaultValue={'live'}
                  choices={[
                    {
                      id: data?.datasets[0]?.key,
                      name: data?.datasets[0]?.name,
                    },
                  ]}
                />
              </Grid2>
            </Grid2>
            {/* Audiences Table */}
            <Grid2 container spacing={3} marginBottom={1}>
              {['Audience', 'Weighting to', 'Weighting in Dataset'].map(
                (label, index) => (
                  <Grid2
                    size={{ xs: 12, sm: 4 }}
                    key={`title-${index.toString()}`}
                  >
                    <Typography
                      sx={{
                        display: { xs: 'none', sm: 'block' },
                        marginBottom: '4px',
                        fontWeight: 'bold',
                      }}
                    >
                      {label}
                    </Typography>
                  </Grid2>
                ),
              )}
            </Grid2>
            <ArrayInput
              defaultValue={data?.datasets[0]?.audiences}
              source="weighting"
              label=""
            >
              <SimpleFormIterator
                inline
                fullWidth
                disableRemove
                disableReordering
                disableAdd
                disableClear
                sx={{ '& .RaSimpleFormIterator-line': { border: 'none' } }}
              >
                <FormDataConsumer>
                  {({ formData, scopedFormData }) => {
                    return (
                      <Box width={'100%'}>
                        <Grid2 container spacing={1}>
                          <Grid2 size={{ xs: 12, sm: 4 }}>
                            <TextField
                              source="name"
                              record={scopedFormData} // Ensure record is passed
                            />
                          </Grid2>
                          <Grid2 size={{ xs: 12, sm: 4 }}>
                            {/* Weighted To */}
                            <SelectInput
                              source="weighting_audience_id"
                              label="Audience"
                              choices={weightingAudience(formData?.audiences)}
                              sx={{ width: { md: 250, xs: '100%' } }}
                              className="white-bg"
                            />
                          </Grid2>
                          <Grid2 size={{ xs: 12, sm: 4 }}>
                            {/* Weight in Dataset */}
                            <NumberInput
                              sx={{ width: { md: 250, xs: '100%' } }}
                              source="weight"
                              step={0.05}
                              label="Weight"
                            />
                          </Grid2>
                        </Grid2>
                      </Box>
                    )
                  }}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>

            {/* Submit and Cancel Buttons */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
                gap: 3,
              }}
            >
              <Button
                variant="outlined"
                color="secondary"
                data-testid={'reWeightingClose'}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                data-testid={'submit-btn'}
                variant="contained"
                color="primary"
                type="submit"
              >
                Re-weight
              </Button>
            </Box>
          </Box>
        </SimpleForm>
      </ResourceContextProvider>
    </Dialog>
  )
}
